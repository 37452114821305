import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from 'sana/elements';
import { renderHTML } from 'sana/utils';
import styles from './ImageBlock.module.scss';

const ImageBlock = ({
  imagePath,
  text,
  textColor,
  showHover,
  canShowHover,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={classNames(
        styles.imageWrapper,
        !imageLoaded && imagePath && styles.showPlaceholder,
      )}
    >
      {imagePath && (
        <LazyImage
          src={imagePath}
          className={styles.tileImage}
          visibleByDefault
          draggable="false"
          afterLoad={() => setImageLoaded(true)}
        />
      )}
      <div
        className={classNames(
          styles.textWrapper,
          textColor && styles.applyFontColor,
        )}
        style={{ color: textColor }}
      >
        <div
          style={{
            visibility: showHover ? canShowHover && 'hidden' : 'visible',
          }}
        >
          {renderHTML(text)}
        </div>
      </div>
    </div>
  );
};

ImageBlock.propTypes = {
  imagePath: PropTypes.string.isRequired,
  text: PropTypes.string,
  textColor: PropTypes.string,
  showHover: PropTypes.bool,
  canShowHover: PropTypes.bool,
};

export default React.memo(ImageBlock);
