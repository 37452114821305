import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderHTML } from 'sana/utils';
import { LinkButton } from 'sana/elements';
import styles from './HoverBlock.module.scss';

const HoverBlock = ({ hoverSection, animationType }) => {
  const {
    hoverText,
    hoverFontColor,
    hoverBackgroundColor,
    buttonText,
    buttonLink,
  } = hoverSection;

  return (
    <div className={classNames(styles.hoverWrapper)}>
      <div
        key={Math.random()}
        className={classNames(
          styles.hoverContainer,
          `animated ${animationType}`,
        )}
      >
        <div className={styles.textWrapper} style={{ color: hoverFontColor }}>
          <div>
            <div
              className={classNames(
                styles.textContainer,
                hoverFontColor && styles.applyFontColor,
              )}
            >
              {renderHTML(hoverText)}
            </div>
            {buttonLink && buttonText && (
              <div className={styles.buttonWrapper}>
                <LinkButton {...buttonLink} className="hover-tile-button-click">
                  {buttonText}
                </LinkButton>
              </div>
            )}
          </div>
        </div>

        <div
          className={classNames(styles.hoverBackground)}
          style={{ backgroundColor: hoverBackgroundColor }}
        />
      </div>
    </div>
  );
};

HoverBlock.propTypes = {
  hoverSection: PropTypes.shape({
    hoverTileImage: PropTypes.string,
    hoverText: PropTypes.string,
    hoverFontColor: PropTypes.string,
    hoverBackgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.object,
  }),
  animationType: PropTypes.string,
};

export default React.memo(HoverBlock);
