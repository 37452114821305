import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageBlock from './imageBlock/ImageBlock';
import HoverBlock from './hoverBlock/HoverBlock';
import 'animate.css';
import styles from './HoverTile.module.scss';

const HoverTile = ({ model }) => {
  const { defaultSection, hoverSection, hoverAnimationSection } = model;
  const [showHover, setShowHover] = useState(false);

  const canShowHover =
    hoverSection.hoverText ||
    hoverSection.buttonText ||
    hoverSection.hoverTileImage ||
    hoverSection.hoverBackgroundColor
      ? true
      : false;

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}
    >
      <ImageBlock
        imagePath={defaultSection.defaultTileImage}
        text={defaultSection.defaultText}
        textColor={defaultSection.defaultFontColor}
        showHover={showHover}
        canShowHover={canShowHover}
      />
      {
        <div
          key={Math.random()}
          className={classNames(
            styles.hoverContainer,
            'animated',
            !canShowHover ? `${hoverAnimationSection.animationType}` : 'fadeIn',
          )}
          style={{ visibility: showHover ? 'visible' : 'hidden' }}
        >
          <ImageBlock
            imagePath={hoverSection.hoverTileImage}
            text={defaultSection.hoverText}
            textColor={defaultSection.hoverFontColor}
          />
        </div>
      }
      {canShowHover && showHover && (
        <HoverBlock
          hoverSection={hoverSection}
          showHover={showHover}
          animationType={
            hoverAnimationSection && hoverAnimationSection.animationType
          }
        />
      )}
    </div>
  );
};

HoverTile.propTypes = {
  model: PropTypes.shape({
    defaultSection: PropTypes.object,
    hoverSection: PropTypes.object,
    hoverAnimationSection: PropTypes.object,
  }),
};

export default React.memo(HoverTile);
